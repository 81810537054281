
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      <div className="App-background-container">
        <div className="App-background">
          <div className="Splash-container">
            <h1>shhh the yetis are sleeping.</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
